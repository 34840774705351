import { remember } from '@epic-web/remember';
import { QueryClient } from '@tanstack/react-query';

import { globalErrorHandler } from './utils';

//
//

export const queryClient = remember(
  'react-query',
  () =>
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 5_000, // 5 seconds
          retry: 0, // retries handled by Ky
        },
        mutations: {
          onError: globalErrorHandler,
        },
      },
    }),
);
